import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import { allFlattenRoutes as routes } from './index';
import { isUserAuthenticated } from '../helpers/authUtils';
import * as layoutConstants from '../constants/layout';

// All layouts/containers
import AuthLayout from '../layouts/Auth';
import VerticalLayout from '../layouts/VerticalLayout';
import EditAffiliatePage from '#pages/settings/EditAffiliate.js';
import EditAchLimitPage from '#pages/resource/EditAchLimit.js';
import ViewUserPage from '#pages/users/view.js';
import EditUserPage from '#pages/users/edit.js';
import GalileoTransactionPage from '#pages/inspect/GalileoTransactions.js';
class Routes extends Component {
    // returns the layout
    getLayout = () => {
        if (!isUserAuthenticated()) return AuthLayout;

        let layoutCls = VerticalLayout;

        switch (this.props.layout.layoutType) {
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    };

    shouldComponentUpdate(nextProps, nextState) {
        let oldLayout = { ...this.props.layout };
        delete oldLayout.showRightSidebar;
        let newLayout = { ...nextProps.layout };
        delete newLayout.showRightSidebar;
        return (
            JSON.stringify(oldLayout) !== JSON.stringify(newLayout) ||
            JSON.stringify(this.props.user) !== JSON.stringify(nextProps.user)
        );
    }

    render() {
        const Layout = this.getLayout();
        // rendering the router with layout
        return (
            <BrowserRouter>
                <Layout {...this.props}>
                    <Route path="/settings/edit/affiliate/:affiliate_id" exact component={EditAffiliatePage} />
                    <Route path="/settings/edit/ach-limit/:ach_limit_id" exact component={EditAchLimitPage} />
                    <Route path="/inspect/galileo/:prn" exact component={GalileoTransactionPage} />
                    <Route path="/users/view/:user_id" exact component={ViewUserPage} />
                    <Route path="/users/edit/:user_id" exact component={EditUserPage} />
                    <Switch>
                        {routes.map((route, index) => {
                            if (!route.children && route.component) {
                                return (
                                    <Route
                                        key={index}
                                        path={route.path}
                                        exact={route.exact}
                                        render={(props) => {
                                            const RouteComponent = route.component;
                                            return <RouteComponent {...props} {...route.props} />;
                                        }}
                                    />
                                );
                            }
                            return null;
                        })}
                    </Switch>
                </Layout>
            </BrowserRouter>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        layout: state.Layout,
        user: state.Auth.user,
    };
};

export default connect(mapStateToProps, null)(Routes);
